






import { Component, Vue } from "vue-property-decorator";
import AdminShopOrderTable from "@/components/admin/shop/order/AdminShopOrderTable.vue";

/**
 * AdminShopArticleManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminShopOrderTable,
    },
})
export default class AdminShopOrderManagement extends Vue {}
