var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dealPrice)?_c('p',[_c('span',{class:[
            'price',
            {
                'currency-position-before': _vm.currencyPosition === 1,
            } ],attrs:{"data-price-currency":_vm.currency}},[_vm._v(" "+_vm._s(_vm.dealPriceLocaleString))]),_c('br'),_c('span',{class:[
            'deal-regular-price',
            {
                'currency-position-before': _vm.currencyPosition === 1,
            } ],attrs:{"data-price-currency":_vm.currency}},[_vm._v(" "+_vm._s(_vm.priceLocaleString))])]):_c('span',{class:[
        'price',
        {
            'currency-position-before': _vm.currencyPosition === 1,
        } ],attrs:{"data-price-currency":_vm.currency}},[_vm._v(" "+_vm._s(_vm.priceLocaleString)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }