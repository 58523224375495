
































































import { Component, Prop, Vue } from "vue-property-decorator";
import ArticlePriceLabel from "@/components/shop/article/price/ArticlePriceLabel.vue";
import { ArticlePrice, ArticleProperty } from "@/types/shop/article";
import { Image } from "@/types/image";

/**
 * ArticleListItem component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        ArticlePriceLabel,
    },
})
export default class ArticleListItem extends Vue {
    @Prop({ type: Number, required: true }) readonly id!: number;
    @Prop({ type: String, required: true }) readonly name!: string;
    @Prop({ type: String, default: null }) readonly description!: string | null;
    @Prop({ type: Object, required: true }) readonly price!: ArticlePrice;
    @Prop({ type: Number, default: undefined }) readonly dealPrice!:
        | number
        | undefined;
    @Prop({ type: Object, required: false }) readonly image?: Image;
    @Prop({ type: Array, default: () => [] })
    readonly properties!: ArticleProperty[];
    @Prop({ type: Boolean, default: false })
    readonly showAddToCartButton!: boolean;

    private quantity = 0;
}
