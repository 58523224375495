





















































































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import {
    UserOrder,
    OrderStatus,
    ShippingServiceProvider,
} from "@/types/shop/order";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";
import ArticlePriceLabel from "@/components/shop/article/price/ArticlePriceLabel.vue";
import CartCommissionList from "@/components/shop/cart/commission/CartCommissionList.vue";

/**
 * AdminShopOrderTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
        ArticlePriceLabel,
        CartCommissionList,
    },
})
export default class AdminShopOrderTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private orders: UserOrder[] = [];
    private orderStates: OrderStatus[] = [];
    private shippingServiceProviders: ShippingServiceProvider[] = [];

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$t("shop.order.number").toString(),
                value: "id",
            },
            {
                text: this.$t("shop.order.customer").toString(),
                value: "user.fullname",
            },
            {
                text: this.$t("shop.order.date").toString(),
                value: "orderDate",
            },
            {
                text: this.$t("shop.order.status").toString(),
                value: "status.name",
            },
            {
                text: this.$t("shop.order.totalPrice").toString(),
                value: "totalPrice.price",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            [this.orders, this.orderStates, this.shippingServiceProviders] =
                await Promise.all([
                    this.fetchOrders(),
                    this.fetchOrderStates(),
                    this.fetchShippingServiceProviders(),
                ]);
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Handler for crudDialogOpen event
     * Sets default tracking values when tracking is null
     *
     * @param mode mode
     * @param item item
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private onCrudDialogOpen(mode: string, item: UserOrder) {
        if (item.tracking === null) {
            item.tracking = {
                shippingServiceProvider: {
                    id: -1,
                    name: "",
                },
                code: "",
                link: "",
            };
        }
    }

    /**
     * Fetches all orders
     *
     * @returns Promise<UserOrder[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchOrders(): Promise<UserOrder[]> {
        const response = (
            await axios.get<APIResponse<UserOrder[]>>("/admin/orders")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data.map((order) => ({
            ...order,
            user: {
                ...order.user,
                fullname: order.user.firstname + " " + order.user.lastname,
            },
        }));
    }

    /**
     * Fetches all order states
     *
     * @returns Promise<OrderStatus[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchOrderStates(): Promise<OrderStatus[]> {
        const response = (
            await axios.get<APIResponse<OrderStatus[]>>("/admin/order/status")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all shipping service providers
     *
     * @returns Promise<ShippingServiceProvider[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchShippingServiceProviders(): Promise<
        ShippingServiceProvider[]
    > {
        const response = (
            await axios.get<APIResponse<ShippingServiceProvider[]>>(
                "/admin/order/shippingserviceprovider"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
