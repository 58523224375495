





































import { Component, Prop, Vue } from "vue-property-decorator";
import CartCommissionItemList from "./item/CartCommissionItemList.vue";
import { CartCommissionItem } from "@/store/modules/cart/types";
import { Article } from "@/types/shop/article";

/**
 * CartCommissionListItem component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        CartCommissionItemList,
    },
})
export default class CartCommissionListItem extends Vue {
    @Prop({ type: Number, required: true }) readonly id!: number;
    @Prop({ type: String, default: null }) readonly name!: string | null;
    @Prop({ type: Array, required: true })
    readonly items!: CartCommissionItem<Article>[];
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;
}
