



























import { Component, Prop, Vue } from "vue-property-decorator";
import CartCommissionListItem from "./CartCommissionListItem.vue";
import { CartCommission } from "@/store/modules/cart/types";
import { Article } from "@/types/shop/article";

/**
 * CartCommissionList component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        CartCommissionListItem,
    },
})
export default class CartCommissionList extends Vue {
    @Prop({ type: Array, required: true })
    readonly commissions!: CartCommission<Article>[];

    @Prop({ type: Boolean, default: false })
    readonly editable!: boolean;
}
