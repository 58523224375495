







































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ArticlePriceLabel extends Vue {
    @Prop({ type: Number, required: true }) readonly price!: number;
    @Prop({ type: Number, default: undefined }) readonly dealPrice!:
        | number
        | undefined;
    @Prop({ type: String, required: true }) readonly currency!: string;
    @Prop({ type: Number, required: true }) readonly currencyPosition!: number;
    @Prop({ type: String, required: true }) readonly locale!: string;

    /**
     * @returns price as localeString
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get priceLocaleString(): string {
        return this.price.toLocaleString(this.locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    /**
     * @returns dealPrice as localeString
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get dealPriceLocaleString(): string {
        if (!this.dealPrice) {
            return "";
        }

        return this.dealPrice.toLocaleString(this.locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
}
